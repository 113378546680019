<template>
  <div>
    <v-card>
      <v-container>
        <v-card-title>
          Users
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search user"
            single-line
            hide-details
            @keyup.enter="getList([0, search])"
          ></v-text-field>
        </v-card-title>
        <v-simple-table>
          <thead>
            <th>Name</th>
            <th>Email</th>
            <th>created_at</th>
          </thead>
          <tbody>
            <tr v-for="item in users" :key="item.id">
              <td v-text="item.name"></td>
              <td v-text="item.email"></td>
              <td v-text="item.created_at"></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-container>

      <v-btn
        bottom
        color="pink"
        dark
        fab
        fixed
        right
        @click="openModel('insert')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <pre>{{ token }}</pre>
      <pre>{{ response }}</pre>
      <pre>{{ permission }}</pre>

    </v-card>

    <v-dialog v-model="dialog" width="800px">
      <v-card>
        <v-card-title class="blue darken-4" style="color: #fff" v-text="tittle">
        </v-card-title>
        <v-container>
          <v-row class="mx-2">
            <v-col cols="6">
              <v-text-field
                label="Name user"
                hide-details="auto"
                v-model="user.name"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="E-mail user"
                hide-details="auto"
                v-model="user.email"
              />
            </v-col>

            <template v-if="typeAction == 1">
              <v-col cols="6">
                <v-text-field
                  label="Password user"
                  hide-details="auto"
                  v-model="user.password"
                />
              </v-col>
            </template>
            <template v-else-if="typeAction == 2">
              <v-col cols="6">
                <v-select
                  item-text="description"
                  item-value="id"
                  :items="changeItem"
                  label="Desea cambiar contraseña?"
                  v-model="changeID"
                ></v-select>
              </v-col>
              <template v-if="changeID == 1">
                <v-col cols="6">
                  <v-text-field
                    label="Contraseña de usuario"
                    hide-details="auto"
                    v-model="user.passwordNew"
                  />
                </v-col>
              </template>
            </template>
            <v-col cols="12" sm="6">
              <v-select
                v-model="user.checkedRole"
                :items="data.roles"
                item-text="name"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                label="Selection roles"
                multiple
                hint="Pick the roles"
                persistent-hint
              ></v-select>
              <pre>{{ user.checkedRole }}</pre>
            </v-col>
            <v-col cols="12">
              <div class="div-error">
                <div class="text-error">
                  <div
                    v-for="(errors, id) in errorMessajeUser"
                    :key="id"
                    v-text="errors"
                  ></div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <div class="my-2">
            <v-btn text color="error" @click="dialog = false">Cancel</v-btn>
          </div>

          <div class="my-2">
            <v-btn text color="success" v-if="typeAction == 1" @click="save"
              >Save</v-btn
            >
            <v-btn text color="success" v-if="typeAction == 2" @click="update"
              >Update</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      search: "",
      dialog: false,
      typeAction: "",
      tittle: "",
      errorUser: 0,
      errorMessajeUser: [],
      user: {
        name: "",
        email: "",
        password: "",
        passwordNew: "",
        checkedRole: [],
      },
    };
  },

  computed: {
    ...mapState("user", ["users", "response", "data"]),
    ...mapState('login', ['token','permission']),
  },

  methods: {
    ...mapActions("user", ["getList", "getData", "saveUser"]),
    openModel(action) {
      this.dialog = true;
      switch (action) {
        case "insert":
          this.typeAction = 1;
          this.tittle = "Insert user";
          this.user.name = "";
          this.user.email = "";
          this.user.password = "";
          this.user.checkedRole = [];
          break;

        default:
          break;
      }
    },

    save() {
      if (this.validate()) {
        return;
      }

      this.saveUser(this.user)
        .then(() => {
          this.dialog = false;
        })
        .catch((e) => {
          if (e.response.data.errors) {
            this.errorMessajeUser = e.response.data.errors;
          }
        });
    },

    update() {},

    validate() {
      this.errorUser = 0;
      this.errorMessajeUser = [];
      if (this.typeAction == 1) {
        if (!this.user.name)
          this.errorMessajeUser.push("*Ingresar nombres de usuario");
        if (!this.user.email)
          this.errorMessajeUser.push("*Ingresar email de usuario");
        if (!this.user.password)
          this.errorMessajeUser.push("*Ingresar password de usuario");
        if (!this.user.checkedRole.length)
          this.errorMessajeUser.push("*Seleccionar roles del usuario");
        if (this.errorMessajeUser.length) this.errorUser = 1;
      }
      return this.errorUser;
    },
  },

  created() {
    this.getList([0, this.search]);
    this.getData();
  },
};
</script>

<style>
.div-error {
  display: flex;
  justify-content: center;
}
.text-error {
  color: red !important;
  font-weight: bold;
  text-align: center;
}
</style>
